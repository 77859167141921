<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        title="模板列表"
        :data="tableData"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              type="plain"
              v-rbac="'operation:price:charge:templateadd'"
              @click="handleBatchSet">
              新增
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      title="充电价格模板"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button
              style="margin-left: 8px"
              :type="btn.type"
              v-if="btn.enabled()"
              :key="i"
              @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit ref="addOrEdit" :currentRow="currentRow" :drawerType="drawerType"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import AddOrEdit from './AddOrEdit.vue';
  import { chargeStrategy } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'chargingPriceTemplateList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        drawerType: 'add',
        showDrawer: false,
        currentRow: null,
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm(() => {
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        tableData: [],
        tableOperateButtons: [
          {
            enabled: () => code('operation:price:charge:templateedit'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doEdit(row, 'edit');
                  }}>
                  编辑
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('operation:price:charge:templatecopy'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doEdit(row, 'copy');
                  }}>
                  复制
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('operation:price:charge:templatedetail'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doDetail(row);
                  }}>
                  详情
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          { label: '模板名称', prop: 'templateName' },
          { label: '修改人', prop: 'modifiedAccount' },
          { label: '修改时间', prop: 'modifiedTime' },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          templateName: '',
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'templateName',
            label: '模板名称',
            placeholder: '请输入模板名称',
          },
        ];
      },
    },
    created() {
      this.searchTableList();
    },
    methods: {
      /**
       * 打开编辑或者复制
       * @param {*} row
       * @param {*} drawerType 【'edit':编辑  'copy': 复制】
       */
      doEdit(row, drawerType) {
        const { templateId } = row;
        chargeStrategy.chargePriceTemplateDetail({ templateId }).then(data => {
          this.currentRow = {
            ...row,
            ...(data || {}),
          };
          this.drawerType = drawerType;
          this.showDrawer = true;
        });
      },
      /**
       * 打开新增
       */
      handleBatchSet() {
        this.drawerType = 'add';
        this.showDrawer = true;
      },
      /**
       * 跳转详情
       * @param {*} param
       */
      doDetail({ templateId }) {
        this.$router.push({
          path: '/operationCenter/priceManage/chargingPriceTemplate/details',
          query: {
            templateId,
          },
        });
      },
      searchTableList() {
        const data = {
          ...this.searchParams,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        chargeStrategy.chargePriceTemplateQuery(data).then(res => {
          this.tableData = res.records;
          this.pageInfo.total = res.total;
          this.pageInfo.pageIndex = res.current;
        });
      },

      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },

      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            // 点击确认取消保存后，要重置表单
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>

<style lang="scss">
  .el-badge__content.is-fixed {
    position: absolute;
    top: 10px;
    right: -8px;
    -webkit-transform: translateY(-50%) translateX(100%);
    transform: translateY(-50%) translateX(100%);
  }
</style>
