<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="模板名称" prop="templateName">
      <ykc-input v-model="ruleForm.templateName" placeholder="请输入模板名称"></ykc-input>
    </ykc-form-item>
    <peaksValleys ref="ykcPrice" :priceData="priceData" />
  </ykc-form>
</template>

<script>
  import { chargeStrategy } from '@/service/apis';

  import peaksValleys from '@/components/dynamic-compose/peaks-valleys.vue';

  export default {
    props: {
      currentRow: {
        type: Object,
        default: () => {
          return { feeList: [], priceList: [] };
        },
      },
      drawerType: {
        type: String,
        default: 'add',
      },
    },
    components: { peaksValleys },
    data() {
      return {
        priceData: { feeList: [], priceList: [] }, // 电价数据
        rules: {
          templateName: [
            {
              required: true,
              message: '请输入模板名称',
              trigger: 'blur',
            },
          ],
          priceList: [
            {
              required: true,
              type: 'array',
              message: '请添加价格设置',
              trigger: 'blur',
            },
          ],
        },
        ruleForm: {
          templateName: '', // 模板名称
          priceList: [], // 价格设置
        },
      };
    },
    watch: {
      'ruleForm.priceList': {
        deep: true,
        handler() {
          this.$refs.ruleForm && this.$refs.ruleForm.validateField('priceList');
        },
      },
    },
    created() {
      if (this.drawerType === 'add') {
        this.resetPriceData();
      } else {
        // 复制 不回显模板名称
        this.ruleForm.templateName = this.drawerType === 'copy' ? '' : this.currentRow.templateName;
        this.priceData = {
          feeList: this.currentRow.feeList || [],
          priceList: this.currentRow.priceList || [],
        };
      }
    },
    methods: {
      /**
       * 重置尖峰平谷数据
       */
      resetPriceData() {
        this.ruleForm.templateName = '';
        this.priceData = {
          feeList: [],
          priceList: [],
        };
      },
      /**
       * 提交
       */
      submitForm(callback) {
        console.log('this.ruleForm+++', this.ruleForm);
        this.PeaksValleysFun(callback);
      },
      /**
       * 尖峰平谷提交
       */
      PeaksValleysFun(callback) {
        console.log('this.$refs.ykcPrice.saveVerifyOMP()+++', this.$refs.ykcPrice.saveVerifyOMP());
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        Promise.all([promisify(this.$refs.ruleForm), this.$refs.ykcPrice.saveVerifyOMP()]).then(
          res => {
            if (res.every(valid => !!valid)) {
              const peaksData = this.$refs.ykcPrice.saveVerifyOMP();
              const parameter = {
                ...this.ruleForm,
                ...peaksData,
              };
              if (this.drawerType === 'add' || this.drawerType === 'copy') {
                console.log('parameter+++add', parameter);
                chargeStrategy.chargePriceTemplateAdd(parameter).then(response => {
                  console.log('chargeStrategy single add', response);
                  callback();
                });
              } else {
                // 编辑保存
                parameter.templateId = this.currentRow?.templateId; // 价格模板ID
                console.log('parameter+++edit', parameter);
                chargeStrategy.chargePriceTemplateEdit(parameter).then(response => {
                  console.log('chargeStrategy single edit', response);
                  callback();
                });
              }
            }
          }
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
